import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 30, mb: 30 }}>
      <Typography variant="h4" gutterBottom sx={{ color: "white" }}>
        Privacy Policy / Gizlilik Politikası
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ color: "white" }}>
        English Version:
      </Typography>

      <List>
        <ListItem>
          <ListItemText
            primary="1. Data Collection"
            secondary="Softcaveware collects personal information such as name, email, and contact details to provide our services effectively."
            primaryTypographyProps={{ sx: { color: "white" } }}
            secondaryTypographyProps={{ sx: { color: "gray" } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. Use of Data"
            secondary="We use your personal data to improve our services, handle requests, and communicate with you."
            primaryTypographyProps={{ sx: { color: "white" } }}
            secondaryTypographyProps={{ sx: { color: "gray" } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. Data Sharing"
            secondary="Your information is not shared with third parties except when required by law or with your explicit consent."
            primaryTypographyProps={{ sx: { color: "white" } }}
            secondaryTypographyProps={{ sx: { color: "gray" } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4.  Data Security"
            secondary="We take reasonable steps to protect your personal data. However, we cannot guarantee complete security."
            primaryTypographyProps={{ sx: { color: "white" } }}
            secondaryTypographyProps={{ sx: { color: "gray" } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="5. User Rights"
            secondary="You have the right to access, correct, or request the deletion of your personal data.

"
            primaryTypographyProps={{ sx: { color: "white" } }}
            secondaryTypographyProps={{ sx: { color: "gray" } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="6. Changes to Privacy Policy
"
            secondary="This policy may be updated from time to time, and changes will be posted on this page.



"
            primaryTypographyProps={{ sx: { color: "white" } }}
            secondaryTypographyProps={{ sx: { color: "gray" } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="7. Contact Information
"
            secondary="For any privacy-related concerns, please contact us at [email].



"
            primaryTypographyProps={{ sx: { color: "white" } }}
            secondaryTypographyProps={{ sx: { color: "gray" } }}
          />
        </ListItem>
      </List>

      <Box mt={4} mb={4}>
        <Typography variant="h6" gutterBottom sx={{ color: "white" }}>
          Türkçe Versiyonu:
        </Typography>

        <List>
          <ListItem>
            <ListItemText
              primary="1. Veri Toplama"
              secondary="Softcaveware, isim, e-posta ve iletişim bilgileri gibi kişisel bilgilerinizi toplar."
              primaryTypographyProps={{ sx: { color: "white" } }}
              secondaryTypographyProps={{ sx: { color: "gray" } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2. Verilerin Kullanımı"
              secondary="Kişisel verilerinizi hizmetlerimizi iyileştirmek, taleplerinizi karşılamak ve sizinle iletişime geçmek için kullanırız."
              primaryTypographyProps={{ sx: { color: "white" } }}
              secondaryTypographyProps={{ sx: { color: "gray" } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="3. Veri Paylaşımı"
              secondary="Bilgileriniz, yasal zorunluluklar veya sizin açık izniniz dışında üçüncü taraflarla paylaşılmaz."
              primaryTypographyProps={{ sx: { color: "white" } }}
              secondaryTypographyProps={{ sx: { color: "gray" } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary=" 4. Veri Güvenliği"
              secondary="Kişisel verilerinizi korumak için makul adımlar atıyoruz. Ancak, tam güvenliği garanti edemeyiz.
"
              primaryTypographyProps={{ sx: { color: "white" } }}
              secondaryTypographyProps={{ sx: { color: "gray" } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary=" 5. Kullanıcı Hakları"
              secondary="Kişisel verilerinize erişme, düzeltme veya silinmesini talep etme hakkına sahipsiniz.

"
              primaryTypographyProps={{ sx: { color: "white" } }}
              secondaryTypographyProps={{ sx: { color: "gray" } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary=" 6. Gizlilik Politikasında Değişiklikler
"
              secondary="Bu politika zaman zaman güncellenebilir ve değişiklikler bu sayfada yayınlanacaktır.


"
              primaryTypographyProps={{ sx: { color: "white" } }}
              secondaryTypographyProps={{ sx: { color: "gray" } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary=" 7. İletişim Bilgileri

"
              secondary="Gizlilikle ilgili herhangi bir konuda bizimle [email] adresinden iletişime geçebilirsiniz.



"
              primaryTypographyProps={{ sx: { color: "white" } }}
              secondaryTypographyProps={{ sx: { color: "gray" } }}
            />
          </ListItem>

          {/* Add more sections similarly */}
        </List>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
