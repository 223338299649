import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 30, mb: 30 }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
        Terms of Service / Hizmet Şartları
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
        English Version:
      </Typography>

      <List>
        <ListItem>
          <ListItemText 
            primary="1. Introduction" 
            secondary="Welcome to Softcaveware. By using our services, you agree to the following terms and conditions."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="2. Service Description" 
            secondary="Softcaveware provides software solutions including design, development, performance optimization, and consultancy. Services may change over time, and updates will be posted."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="3. Account Responsibilities" 
            secondary="Users are responsible for maintaining the confidentiality of their account information. Unauthorized use of your account should be reported immediately."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
        {/* New sections */}
        <ListItem>
          <ListItemText 
            primary="4. Payment" 
            secondary="Payments for services must be made as agreed upon at the time of purchase. Softcaveware reserves the right to adjust prices at any time."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="5. Intellectual Property" 
            secondary="All content provided by Softcaveware, including code, designs, and documentation, remains the property of Softcaveware."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="6. Limitation of Liability" 
            secondary="Softcaveware is not responsible for any indirect damages resulting from the use or inability to use our services."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="7. Changes to the Terms" 
            secondary="Softcaveware may revise these terms at any time, with changes effective upon posting on this page."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="8. Governing Law" 
            secondary="These terms are governed by the laws of [Your Country/Region]."
            primaryTypographyProps={{ sx: { color: 'white' } }}
            secondaryTypographyProps={{ sx: { color: 'gray' } }}
          />
        </ListItem>
      </List>

      <Box mt={4} mb={4}>
        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
          Türkçe Versiyonu:
        </Typography>

        <List>
          <ListItem>
            <ListItemText 
              primary="1. Giriş" 
              secondary="Softcaveware'e hoş geldiniz. Hizmetlerimizi kullanarak aşağıdaki şartları kabul etmiş oluyorsunuz."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="2. Hizmet Açıklaması" 
              secondary="Softcaveware, tasarım, geliştirme, performans optimizasyonu ve danışmanlık hizmetleri sunar. Hizmetler zamanla değişebilir ve güncellemeler burada yayınlanacaktır."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="3. Hesap Sorumlulukları" 
              secondary="Kullanıcılar hesap bilgilerinin gizliliğinden sorumludur. Hesabınızın yetkisiz kullanımını derhal bildirmelisiniz."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
          {/* New sections in Turkish */}
          <ListItem>
            <ListItemText 
              primary="4. Ödeme" 
              secondary="Hizmetler için ödemeler, satın alma sırasında kararlaştırıldığı gibi yapılmalıdır. Softcaveware, fiyatları dilediği zaman değiştirme hakkını saklı tutar."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="5. Fikri Mülkiyet" 
              secondary="Softcaveware tarafından sağlanan tüm içerik, kod, tasarımlar ve belgeler Softcaveware'in mülkiyetindedir."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="6. Sorumluluğun Sınırlandırılması" 
              secondary="Softcaveware, hizmetlerimizin kullanımından veya kullanılamamasından kaynaklanan dolaylı zararlardan sorumlu değildir."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="7. Şartlardaki Değişiklikler" 
              secondary="Softcaveware, bu şartları istediği zaman değiştirebilir ve değişiklikler bu sayfada yayınlandıktan sonra geçerli olur."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="8. Yürürlükteki Kanun" 
              secondary="Bu şartlar [Ülkeniz/Bölgeniz] yasalarına tabidir."
              primaryTypographyProps={{ sx: { color: 'white' } }}
              secondaryTypographyProps={{ sx: { color: 'gray' } }}
            />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default TermsOfService;
